import validate from 'validate.js'

const constraints = {
  firstName: {
    presence: true,
    length: {
      minimum: 1,
      // maximum: 10,
    },
  },
  lastName: {
    presence: true,
    length: {
      minimum: 1,
      // maximum: 10,
    },
  },
  phoneDialCode: {
    presence: true,
    format: {
      pattern: /\+[0-9| ]+/,
    },
  },
  phoneNumber: {
    presence: true,
    format: {
      pattern: /^[0-9]+$/,
    },
  },
  birthDay: {presence: true},
  birthMonth: {presence: true},
  birthYear: {presence: true},
  address: {
    presence: true,
    length: {
      minimum: 1,
    },
  },
  city: {
    presence: true,
    length: {
      minimum: 1,
    },
  },
  state: {
    presence: true,
    length: {
      minimum: 1,
    },
  },
  zipCode: {
    presence: true,
    length: {
      minimum: 1,
    },
  },
  countryId: {presence: true},
  spokenLanguages: {
    presence: true,
    length: {
      minimum: 1,
    },
  },
}
export default (data) => {
  return validate(data, constraints)
}
