import React, {useState} from 'react'
import {
  IconClose,
  Modal,
  useWindowDimensions,
} from '@Knowledge-OTP/znk-ui-components'
import useTranslation from '../../../../i18n/useTranslation'
import Skeleton from 'react-loading-skeleton'
import {useQuery} from '@apollo/client'
import historyQuery from './historyQuery'
import StatusesFilter, {baseStatuses} from './Filters/Statuses'
import CategoriesFilter, {baseCategories} from './Filters/Categories'
import DateFilter from './Filters/Date'
import isEqual from 'lodash/isEqual'
// import useModalState from '../../../../hooks/useModalState'
import PaymentsListItem from './PaymentsListItem'
import {TabContent, TabPane} from 'reactstrap'
import PayslipsHistoryComponent from './Components/PayslipsHistory'

const PaymentHistory = (props) => {
  const {isOpen, toggle, isPayslips = false, payslipsData = []} = props
  const {breakWidth} = useWindowDimensions()
  // const {onChange} = useModalState('payments.viewInvoice')
  const {translate} = useTranslation()
  const [statuses, setStatuses] = useState(baseStatuses)
  const [categories, setCategories] = useState(baseCategories)
  const [dateFilter, setDateFilter] = useState([])
  const {data, loading} = useQuery(historyQuery, {
    variables: {
      status: statuses.length ? statuses : baseStatuses,
      category: categories.length ? categories : baseCategories,
      dates: dateFilter,
    },
    fetchPolicy: 'network-only',
  })
  const paymentsData = data ? data.getInvoicePayments : []
  return (
    <Modal
      className={`modal-${
        breakWidth === 'SM' ? 'botbar' : 'sidebar modal-desktop-half'
      } modal-colored`}
      backdropClassName={breakWidth === 'SM' ? '' : 'no-op'}
      isOpen={isOpen}
      toggle={toggle}
      Head={
        <div className='modal-header pt-7 px-4 px-md-7 d-flex flex-row align-items-center justify-content-between'>
          <span className='h2 font-weight-bold'>
            {translate('profile.actions.payments.history')}
          </span>
          <IconClose size={20} onClick={toggle} />
        </div>
      }
    >
      <TabContent
        activeTab={loading ? 'loading' : isPayslips ? 'payslips' : 'payments'}
        className='h-inherit'
      >
        <TabPane tabId='payments'>
          <div className='w-100 p-0 m-0 px-4 px-md-7 pb-4'>
            <div className='d-flex'>
              <span className='font-weight-bold btn btn-link p-0 text-logo'>
                {!loading ? (
                  translate('misc.results', paymentsData.length)
                ) : (
                  <Skeleton />
                )}
              </span>
            </div>
            <div className='d-flex justify-content-between py-2 mb-2'>
              <div className='d-flex align-items-center'>
                <StatusesFilter {...{statuses, setStatuses}} />
                <CategoriesFilter {...{categories, setCategories}} />
                <DateFilter {...{dateFilter, setDateFilter}} />
              </div>
              {dateFilter.length === 2 ||
              !isEqual(statuses, baseStatuses) ||
              !isEqual(categories, baseCategories) ? (
                <span
                  className='btn btn-link p-0 text-logo'
                  onClick={() => {
                    setCategories(baseCategories)
                    setStatuses(baseStatuses)
                    setDateFilter([])
                  }}
                >
                  {translate('misc.clearFilters')}
                </span>
              ) : null}
            </div>
            <div className='d-flex flex-column align-items-center'>
              {loading ? (
                <Skeleton height={20} count={3} />
              ) : paymentsData.length ? (
                <>
                  {paymentsData.map((invoice) => {
                    return <PaymentsListItem invoice={invoice} />
                  })}
                </>
              ) : (
                <span className='w-100 text-center'>
                  {translate('misc.nothingFound')}
                </span>
              )}
            </div>
          </div>
        </TabPane>
        <TabPane tabId='payslips'>
          {isPayslips && <PayslipsHistoryComponent data={payslipsData} />}
        </TabPane>
        <TabPane tabId='loading'>
          <Skeleton count={1} height={700} className='w-100' />
        </TabPane>
      </TabContent>
    </Modal>
  )
}

export default PaymentHistory
