import React, {useState, useMemo, useEffect} from 'react'
import {
  Modal,
  Button,
  useWindowDimensions,
  IconPayments,
  IconCardVisa,
  IconCardMastercard,
  IconCardDiners,
  IconCardDiscover,
  Field,
  LabeledField,
  Form,
  IconClose,
} from '@Knowledge-OTP/znk-ui-components'
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import {FormGroup, FormText, ModalHeader} from 'reactstrap'
import {showAlert} from '../../../common/Alert/util'
import {useDispatch, useSelector} from 'react-redux'
import useTranslation from '../../../i18n/useTranslation'
import useSetCard from './useCard'
import useGetPaymentMethod from '../Model/useGetPaymentMethod'
import isEqual from 'lodash/isEqual'

const PaymentMethod = (props) => {
  const {
    isOpen,
    toggle,
    isAdmin,
    refetchOwner,
    primary = false,
    studentUserId = null /** se usa para counseling onboarding**/,
    isParent = false,
    registeredPaymentMethods = [],
  } = props
  const dispatch = useDispatch()
  const {translate} = useTranslation()
  const stripe = useStripe()
  const {breakWidth} = useWindowDimensions()
  const {refetch} = useSelector((state) => state.user)
  const {dataPlan} = useSelector((state) => state.modal.onboarding.data)
  const [form, setForm] = useState({})
  const elements = useElements()
  const [paymentMethodId, setPaymentMethodId] = useState(null)
  const validation = useMemo(() => {
    return form.cvc && form.exp && form.number
  }, [form])
  const [loadHandler, setLoadHandler] = useState(false)
  const {submit: setCard, loading} = useSetCard((data) => {
    const {success, error} = data.data.setCardPaymentMethod
    if (success) {
      if (isAdmin || isParent) refetchOwner()
      else refetch()
      setTimeout(() => {
        toggle()
        setLoadHandler(false)
      }, 1500)
    } else {
      setLoadHandler(false)
      showAlert(
        {
          text: translate(`profile.actions.addCard.${error}`),
          status: 'error',
        },
        dispatch,
      )
    }
  }, console.log)
  const userId = isAdmin || isParent ? dataPlan?.studentUserId || studentUserId : null
  const handleSubmit = async (event) => {
    setLoadHandler(true)
    if (!form.cvc && !form.exp && !form.number) {
      alert('datos invalidos')
      return
    }
    try {
      const {paymentMethod, error} = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardNumberElement),
      })
      if (!error) {
        setPaymentMethodId(paymentMethod.id)
      } else {
        setLoadHandler(false)
        showAlert(
          {
            text: translate(`profile.actions.addCard.${error.code}`),
            status: 'error',
          },
          dispatch,
        )
      }
    } catch (e) {
      showAlert(
        {
          text: JSON.stringify(e),
          status: 'error',
        },
        dispatch,
      )
    }
  }
  const {
    data: dataPaymentMethod,
    loading: loadingGetPaymentMethod,
    error: errorGetPaymentInfo,
  } = useGetPaymentMethod({
    variables: {paymentMethodId},
    skip: !paymentMethodId,
  })
  if (errorGetPaymentInfo) {
    showAlert(
      {
        text: JSON.stringify(errorGetPaymentInfo),
        status: 'error',
      },
      dispatch,
    )
  }

  useEffect(() => {
    if (dataPaymentMethod) {
      const isCardRegistered = registeredPaymentMethods.some(({ cardInfo }) =>
        isEqual(cardInfo, dataPaymentMethod?.getPaymentMethodInfo)
      );
      if (isCardRegistered) {
        showAlert(
          {
            text: 'This card is already registered',
            status: 'error',
          },
          dispatch
        );
        setLoadHandler(loadingGetPaymentMethod);
      } else {
        setCard({ paymentMethodId, userId });
      }
    } else if (errorGetPaymentInfo) {
      showAlert(
        {
          text: JSON.stringify(errorGetPaymentInfo),
          status: 'error',
        },
        dispatch
      );
    }
    // eslint-disable-next-line
  }, [dataPaymentMethod, errorGetPaymentInfo]);

  console.log(loadHandler)
  console.log(loading)
  console.log(loadingGetPaymentMethod)
  return (
    <span>
      <Modal
        backdrop={[false, 'static']}
        buttonLabel={null}
        className={`modal-${breakWidth === 'SM' ? 'botbar' : 'centered'}`}
        isOpen={isOpen}
        toggle={toggle}
        Head={
          <ModalHeader close={<IconClose onClick={toggle} />}>
            <IconPayments size={breakWidth === 'SM' ? 48 : 70} color />
          </ModalHeader>
        }
      >
        <div className='d-flex flex-column m-3'>
          <h2 className='htext mt-0'>Add payment method</h2>
          <Form className='stripe' value={form} onChange={setForm}>
            <FormGroup>
              <Field
                className='col-12'
                type={LabeledField}
                component={CardNumberElement}
                fieldName='number'
                placeholder='Card number'
              />
              <FormText>
                {form.number && form.number.error && (
                  <span className='text-error'>
                    {form.number.error.message}
                  </span>
                )}
              </FormText>
            </FormGroup>
            <div className='row'>
              <div className='col-6'>
                <FormGroup>
                  <Field
                    type={LabeledField}
                    component={CardExpiryElement}
                    fieldName='exp'
                    placeholder='MM / AA'
                  />
                </FormGroup>
              </div>
              <div className='col-6'>
                <FormGroup>
                  <Field
                    type={LabeledField}
                    component={CardCvcElement}
                    fieldName='cvc'
                    placeholder='CVV'
                  />
                </FormGroup>
              </div>
            </div>
            <div className='row'>
              <div className='col-12 pb-4 text-justify colort'>
                Cambiar método de pago
              </div>
            </div>
            <div className='row'>
              <div className='col-12 mb-4 text-justify'>
                <div className='accepted'>
                  <span className='span-accep'>Accepted cards</span>
                  <IconCardVisa size={30} />
                  <IconCardDiners size={30} className='cardp' />
                  <IconCardDiscover size={30} className='cardp' />
                  <IconCardMastercard size={30} className='cardp' />
                </div>
              </div>
              <span className='text-muted col-12 mt-0 mb-5'>
                <small>
                  Your credit card will not be charged at this time but instead
                  pre-authorized for future charges based on the payment
                  schedule described here.
                </small>
              </span>
            </div>
          </Form>
          <Button
            disabled={!validation}
            label='Pre-authorize payment schedule'
            style={{
              backgroundColor: primary ? '' : '#FFB519',
              border: 'none',
              width: '100%',
            }}
            onClick={handleSubmit}
            color={primary ? 'primary' : ''}
            activity={loading || loadHandler || loadingGetPaymentMethod}
          />
        </div>
      </Modal>
    </span>
  )
}

PaymentMethod.propTypes = {}

export default PaymentMethod
