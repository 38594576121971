import React, {useMemo, useEffect} from 'react'
import {
  ListItem,
  IconDate,
  IconInfo,
  ToolTipInfo,
} from '@Knowledge-OTP/znk-ui-components'
import Skeleton from 'react-loading-skeleton'
import useTranslation from '../../../i18n/useTranslation'
import moment from 'moment'
import {useDispatch} from 'react-redux'

const CheckInType = {
  scoreExpectation: 'home.onboard.approve.types.scoreExpectation',
  regular: 'home.onboard.approve.types.regular',
  undefined: 'home.onboard.approve.types.regular',
}

const CheckInsPlan = (props) => {
  const dispatch = useDispatch();
  const {data = []} = props
  const datesCheckIn = data?.checkInDates.map((check) => {
    return {id: check.id, date: check.date, type: check.type}
  })
  const defaultDates = useMemo(() => {
    if (!datesCheckIn) return []
    const hasPastDate = datesCheckIn.some(
      (date) => new Date(date.date) < new Date(data?.startDate),
    )
    if (!hasPastDate) return []
    const dates = []
    const totalDays = Math.abs(
      moment.duration(moment(data?.startDate).diff(moment(data?.endDate))).asDays(),
    )
    if (!data?.isTestDate) {
      //si el plan dura mas de 5 semanas colocar checkin cada 5 semanas
      if (totalDays > 7 * 5 /**5 semanas**/) {
        //calcula cuantos rangos de 5 semanas hay entre [data?.startDate] y [data?.endDate menos 7 días]
        let totalRanges = Math.abs(
          moment
            .duration(moment(data?.startDate).diff(moment(data?.endDate).subtract(7, 'days')))
            .asDays(),
        )
        totalRanges = Math.floor(totalRanges / (7 * 5))
        const startMoment = moment(data?.startDate).clone()
        for (let t = 1; t <= totalRanges; t++) {
          dates.push({
            date: startMoment
              .clone()
              .add(t * 5, 'weeks')
              .toISOString(),
          })
        }
      }
    } else {
      const midDate = moment(data?.startDate)
        .add(Math.floor(totalDays / 2), 'days')
        .toISOString()
      dates.push({date: midDate})
    }
    const beforeFinal =
      totalDays >= 7
        ? moment(data?.endDate).subtract(7, 'days').toISOString()
        : moment(data?.endDate).subtract(1, 'days').toISOString()
    return [
      // { date: midDate },
      ...dates,
      {date: beforeFinal},
    ]
    // eslint-disable-next-line
  }, [ data?.startDate, data?.endDate, data?.isTestDate])

  let finalDatesCheckIn = datesCheckIn
  if (defaultDates.length > 0) {
    const checkInsToReplace = datesCheckIn.slice(0, 2)
    const newDatesCheckIn = checkInsToReplace.map((checkin, index) => {
      return {
        ...checkin,
        date: defaultDates[index]?.date || moment().format('YYYY-MM-DD'),
      }
    })
    finalDatesCheckIn = [...newDatesCheckIn, ...datesCheckIn.slice(2)]
  }

  useEffect(() => {
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'onboarding.data.dataPlan.checkInDates',
      data: finalDatesCheckIn,
    })
    // eslint-disable-next-line
  }, [dispatch, defaultDates])
  const {translate} = useTranslation()
  return (
    <div className='my-5 mx-0'>
      <div className='w-100 d-flex flex-row align-items-center justify-content-between'>
        <h3>
          <b>{translate('home.onboard.approve.checkInsTitle')}</b>
        </h3>
        <ToolTipInfo
          type='dark'
          place='left'
          innerElement={
            <p className='p-0 m-0' style={{maxWidth: '200px'}}>
              {translate('home.onboard.approve.checkInsInfo')}
            </p>
          }
        >
          <div className='hover-icon'>
            <IconInfo className='text-gray' size={16} />
          </div>
        </ToolTipInfo>
      </div>
      <div className='m-0 p-0 my-4'>
        {finalDatesCheckIn ? (
          <>
            {finalDatesCheckIn?.map((check, index) => {
              const {date, type} = check
              return (
                <ListItem
                  key={index}
                  showTopLine={false}
                  className='pl-0 py-1'
                  Left={
                    <div className='d-flex flex-row align-items-center text-muted'>
                      <IconDate size={20} />
                      <span className='ml-4 text-truncate'>
                        {moment(date).utc().format('MMMM Do, YYYY')}
                      </span>
                    </div>
                  }
                  Right={
                    <span className='text-truncate'>
                      {translate(CheckInType[type])}
                    </span>
                  }
                />
              )
            })}
          </>
        ) : (
          <Skeleton count={4} />
        )}
      </div>
    </div>
  )
}

export default CheckInsPlan
