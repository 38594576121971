import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {db, auth} from '../../Pages/VirtualChat/fireBase'
import {messagesToArray} from '../../Pages/VirtualChat/helpers/getMessages'
import {gql, useApolloClient} from '@apollo/client'
import queryLessonResult from '../../Model/QueryResult/useLessonResult'
import moment from 'moment-timezone'
import {showAlert} from '../Alert/util'
import NotificationSound from '../ChatNotification/SD_ALERT_20-cut.mp3'
import {useHistory} from 'react-router'
localStorage.setItem(
  'messageRead',
  localStorage.getItem('messageRead')
    ? localStorage.getItem('messageRead')
    : null,
)

const ChatNotification = () => {
  const user = auth.currentUser
  const sound = new Audio(NotificationSound)
  const idAlert = new Date().getTime()
  const client = useApolloClient()
  const dispatch = useDispatch()
  const history = useHistory()
  const {roles, id: staticUserID} = useSelector((state) => state.user.info)
  const [notificationPermission, setNotificationPermission] = useState(null)

  const listenMessages = (inside = () => {}) => {
    db.collection('messages').onSnapshot(() => {
      getUpcommingLesson().then((lesson) => {
        if (!lesson) return
        if (moment() >= moment(lesson?.endDate)) return
        const {id} = lesson
        db.collection('messages')
          .doc(id)
          .get()
          .then(async (resp) => {
            await inside(lesson, resp?.data())
          })
      })
    })
  }

  const showNotification = (lesson, data) => {
    if (!data) return
    const messages = messagesToArray(data)
    if (!(messages?.length > 0)) return
    const lastMessage = messages[messages.length - 1]
    const {userID, text, userName, index, readed = {}} = lastMessage
    // si el mensaje ya ha sido leido no muestra la notificación
    if (readed[userID]?.readed) return

    const formatTextId = `${index}_${userID}_${lesson?.id}`

    // status del estudiante en el chat de la lección
    // let onlineStatus = localStorage.getItem(
    //   `status-${lesson?.id}-${staticUserID}`,
    // )
    // onlineStatus = !!JSON.parse(onlineStatus)?.online
    // if (onlineStatus) return

    if (formatTextId === localStorage.getItem('messageRead')) return
    localStorage.setItem('messageRead', formatTextId)
    localStorage.setItem(
      'unreadMessages',
      parseInt(localStorage.getItem('unreadMessages')) + 1,
    )
    if (userID !== staticUserID) {
      const finalText = `${text.substring(0, 40)} ${
        text.substring(0, 40).length >= 40 ? '.....' : ''
      }`
      sound.play()
      if (localStorage.getItem('lastIdAlert')) {
        dispatch({
          type: 'REMOVE_ALERT',
          payload: {id: idAlert},
        })
      }
      localStorage.removeItem('lastIdAlert')

      localStorage.setItem('lastIdAlert', idAlert)

      showAlert(
        {
          text: `${userName}: ${finalText} `,
          status: 'message',
          time: 10000,
          id: idAlert,
          permanet: true,
          unreadMsgs: localStorage.getItem('unreadMessages')
            ? parseInt(localStorage.getItem('unreadMessages'))
            : 0,
          onClick: () => {
            dispatch({
              type: 'REMOVE_ALERT',
              payload: {id: idAlert},
            })
            localStorage.setItem('unreadMessages', 0)
            localStorage.removeItem('lastIdAlert')
            if (roles.includes('EDUCATOR')) {
              history.push('/lessons')
              dispatch({
                type: 'SET_DATA_MODAL_FORM_LIST',
                payload: 'calendar.infoLessonModal',
                data: {
                  isOpen: true,
                  dataLesson: lesson,
                  modalType: 'chat',
                },
              })
            }
            if (roles.includes('STUDENT')) {
              history.push('/calendar/calendar-view')
              dispatch({
                type: 'SET_DATA_MODAL_FORM_LIST',
                payload: 'student.infoLessonModal',
                data: {
                  isOpen: true,
                  idLesson: lesson.id,
                  modalType: 'chat',
                },
              })
            }
          },
        },
        dispatch,
      )
      if (notificationPermission === 'granted') return
      new Notification(userName, {
        body: text,
        silent: true,
      })
    }
  }
  const getUpcommingLesson = async () => {
    try {
      const {data} = await client.query({
        query: gql`
            query ($userId: String!){
              userUpcomingLesson(userId: $userId){
                ${queryLessonResult}
              }
            }
          `,
        variables: {userId: staticUserID},
        errorPolicy: 'ignore',
        fetchPolicy: 'no-cache',
      })
      return data?.userUpcomingLesson || null
    } catch (err) {
      return null
    }
  }
  const checkNotificationPromise = () => {
    try {
      Notification.requestPermission().then()
    } catch (e) {
      return false
    }
    return true
  }

  useEffect(() => {
    if (user) {
      if (checkNotificationPromise()) {
        if (notificationPermission === 'granted') {
          setNotificationPermission(Notification.permission)
        } else if (notificationPermission !== 'denied') {
          Notification.requestPermission(function (permission) {
            setNotificationPermission(permission)
          })
        }
      }
      listenMessages((lesson, resp) => {
        showNotification(lesson, resp)
      })
    }
    // eslint-disable-next-line
  }, [notificationPermission])

  return <></>
}

export default ChatNotification
