import {useDispatch, useSelector} from 'react-redux'
import io from 'socket.io-client'
import {
  EMPTY_ENTITY_UPDATED,
  ADD_ENTITY_UPDATED,
} from '../../../store/socket/const'

const connection = io(
  // 'ws://localhost:3000',
  'wss://dev.api2.zinkerz.com:4000',
  {transports: ['websocket']},
)

const useSocketEntities = () => {
  const dispatch = useDispatch()
  const {socket, nModified, entities} = useSelector((state) => state.socket)
  const emit = (entities) => {
    // emite la actualización de una entidad (a si mismo)
    dispatch({
      type: ADD_ENTITY_UPDATED,
      entities,
    })
  }
  const empty = () => {
    // vacía el arreglo de entidades en redux
    dispatch({
      type: EMPTY_ENTITY_UPDATED,
    })
  }
  return {
    connection,
    emit, // función para añadir entidades a `entities`
    empty, // vacía `entities` (pasa a {})
    socket, // socket connection
    entities, // entidades con el formato {[entity]: [ids]}
    nModified, // cuenta cuentas veces se ha modificado `entities`, sirve como dependecia
  }
}

export default useSocketEntities
