import {useLazyQuery, gql} from '@apollo/client'

const useGetUser = () => {
  return useLazyQuery(
    gql`
      query($id: String!) {
        user(id: $id) {
          id
          emails {
            address
            verified
          }
          countryId
          gender
          spokenLanguages
          address
          city
          state
          zipCode
          firstName
          lastName
          phone
          timezone
          birthdate
          isActive
          roles
        }
      }
    `,
  )
}

export default useGetUser
