import React, {useState, useEffect} from 'react'
import {
  Modal,
  Button,
  useWindowDimensions,
} from '@Knowledge-OTP/znk-ui-components'
// import {useSelector} from 'react-redux'
// Components
import StudentModalForm from './Student/StudentInfo'
import PersonalModalForm from './Personal/PersonalModalForm'
import useTranslation from '../../i18n/useTranslation'
import hashQuery from '../../queries/user-hash'
import {useQuery} from '@apollo/client'

const personalObligatoryFields = [
  'firstName',
  'lastName',
  'phone',
  'address',
  'city',
  'state',
  'zipCode',
  'countryId',
  'spokenLanguages',
  'birthdate',
]
const studentObligatoryFields = ['institution', 'graduationDate']

const CompleteProfile = () => {
  const {data, loading:loadingRefetch, refetch} = useQuery(hashQuery, {
    nextFetchPolicy: 'standby',
  })
  const userData = data && {
    ...data.me,
    studentInfo: data?.me?.studentInfo || {},
    educatorInfo: data?.me?.educatorInfo || {},
  }
  // const userData = useSelector((state) => state.user.info)

  const {translate} = useTranslation()
  const isStudent = userData?.roles?.includes('STUDENT') ?? false
  const [errorValues, setErrorValues] = useState({
    personalInfoErrors: undefined,
    studentInfoErrors: undefined,
    academicInfoErrors: undefined,
  })
  const [personalInfoCallBack, setPersonalInfoCallBack] = useState()
  const [studentInfoCallBack, setStudentInfoCallBack] = useState()
  const [formState, setFormState] = useState()
  const [isOpen, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const handleSubmit = () => {
    if (formState) {
      personalInfoCallBack()
      if (isStudent) {
        studentInfoCallBack()
      }
    }
  }

  useEffect(() => {
    const {personalInfoErrors, studentInfoErrors} = errorValues
    let state = personalInfoErrors === undefined
    if (isStudent) {
      state =
        personalInfoErrors === undefined && studentInfoErrors === undefined
    }
    setFormState(state)
    if (loadingRefetch) {
      setLoading(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorValues])
  useEffect(() => {
    if (userData) {
      const missingData = personalObligatoryFields.filter(
        (field) => !userData[field],
      )
      if (isStudent) {
        missingData.push(
          ...studentObligatoryFields.filter(
            (field) => !userData.studentInfo[field],
          ),
        )
      }
      const finalCondition = missingData.length > 0
      // completar perfil solo cuando el
      // usuario tiene algun plan o lección activos
      setOpen(finalCondition && userData?.checkIfUserHasActiveEvents)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData])

  const {breakWidth} = useWindowDimensions()
  return (
    <Modal
      isOpen={isOpen}
      className={`modal-${breakWidth === 'SM' ? 'botbar' : 'centered'}`}
      backdrop='static'
    >
      <h2
        className='htext mt-7 mx-4 text-center'
        style={{fontSize: '25px', color: '#2C3F78'}}
      >
        {translate('common.completeProfile.requiredInformation')}
      </h2>
      <div className='mx-4'>
        <div className='mb-5 mt-7'>
          <h3 className='modal-title'>
            <span className='font-weight-bold mr-4'>
              {translate('profile.personalInfo')}
            </span>
            <hr />
          </h3>
        </div>
        <PersonalModalForm
          {...userData}
          formState={formState}
          setPersonalInfoCallBack={setPersonalInfoCallBack}
          setErrorValues={setErrorValues}
          refetch={refetch}
          setLoading={setLoading}
        />
        {isStudent && (
          <>
            <div className='mb-5 mt-7'>
              <h3 className='modal-title'>
                <span className='font-weight-bold mr-4'>
                  {translate('profile.studentInfo')}
                </span>
                <hr />
              </h3>
            </div>
            <StudentModalForm
              {...userData}
              formState={formState}
              setStudentInfoCallBack={setStudentInfoCallBack}
              setErrorValues={setErrorValues}
              refetch={refetch}
              setLoading={setLoading}
            />
          </>
        )}
        <Button
          disabled={!formState || loading}
          activity={loading}
          onClick={handleSubmit}
          label={translate('misc.saveChanges')}
          color='primary'
          check
          size='medium'
          className='w-100'
        />
      </div>
    </Modal>
  )
}

export default CompleteProfile
