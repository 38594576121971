import React, {useState, useEffect} from 'react'
import {
  IconArrowLeft,
  ListItem,
  UserBubble,
} from '@Knowledge-OTP/znk-ui-components'
import UserItems from '../UserItems'
import {useSelector} from 'react-redux'
import InvokeAdmin from './InvokeAdmin'
import {db} from '../../fireBase'

const ActualUser = (props) => {
  const {
    usersOnChat = [],
    loading,
    collection,
    backArrow,
    invokeAdminInfo,
    refetch = () => {},
    setAdminInfo = () => {},
  } = props
  const {firstName, lastName, id: userID /*, avatar */} = useSelector(
    (state) => state.user.info,
  )
  const eventID = collection?.id || collection?.taskId

  const [onlineStatus, setOnlineStatus] = useState({})
  useEffect(() => {
    if (eventID && userID) {
      db.collection('usersStatus')
        .doc(eventID)
        .collection(userID)
        .doc('status')
        .onSnapshot((snapshot) => {
          const onlineStatus = snapshot.data()
          // guarda status del estudiante en el chat de la lección
          localStorage.setItem(
            `status-${eventID}-${userID}`,
            JSON.stringify(onlineStatus),
          )
          setOnlineStatus(onlineStatus)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const useInvokeAdmin = true

  return (
    <div style={{position: 'sticky', backgroundColor: 'white'}}>
      <ListItem
        showBottomLine
        showTopLine={false}
        classNameLeft='ellipsis-text'
        classNameRight='flex-grow-2'
        style={{height: '70px'}}
        Left={
          <div className='d-flex flex-row align-items-center'>
            <div className='hover-icon mr-1'>
              <IconArrowLeft onClick={backArrow} />
            </div>
            {loading && (
              <div className='d-flex flex-row m-0 p-0 ml-2'>
                <UserBubble
                  // imgContent={{img: avatar ? avatar : null, size: 16}}
                  name={`${firstName} ${lastName}`}
                  online={onlineStatus?.online}
                  className='mr-2'
                  toolTipProperties={{
                    referenceID: `userBubble${userID}`,
                    toolTipText: 'You',
                  }}
                />
                <div className='border-right mr-2' />
                <UserItems usersList={usersOnChat} eventID={eventID} />
              </div>
            )}
          </div>
        }
        Right={
          <div className='d-flex flex-row justify-content-end'>
            {useInvokeAdmin ? (
              <InvokeAdmin
                setUserInfo={setAdminInfo}
                refetch={() => {
                  refetch()
                }}
                invokeAdminInfo={invokeAdminInfo}
                eventID={eventID}
              />
            ) : (
              <></>
            )}
          </div>
        }
      />
    </div>
  )
}

export default ActualUser
