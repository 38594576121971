import React, {useEffect, useState} from 'react'
import {
  IconLoad,
  ToolTipInfo,
  UserBubble,
} from '@Knowledge-OTP/znk-ui-components'
import {TabContent, TabPane} from 'reactstrap'
import {markAsReader} from '../../../helpers/getMessages'
import useInvokeAdmin from '../../../Model/useInvokeAdmin'
import {showAlert} from '../../../../../common/Alert/util'
import {useDispatch} from 'react-redux'
import useGetUser from '../../../Model/useGetUser'
import {db} from '../../../fireBase'
import useEmitInvokeLessonForAdmin from '../../../Model/useEmitInvokeLessonForAdmin'
import useTranslation from '../../../../../i18n/useTranslation'

const InvokeAdmin = (props) => {
  const {
    eventID,
    invokeAdminInfo,
    refetch = () => {},
    setUserInfo = () => {},
  } = props
  const {translate} = useTranslation()
  const [adminInfo, setAdminInfo] = useState(null)
  const [getUser, {loading: loadUser}] = useGetUser()
  useEffect(() => {
    // obtiene información del administrador
    if (invokeAdminInfo) {
      getUser({variables: {id: invokeAdminInfo?.invokeUserId}}).then((res) => {
        setAdminInfo(res?.data?.user)
        setUserInfo(res?.data?.user)
      }, console.error)
    }
    // eslint-disable-next-line
    }, [invokeAdminInfo])

  const dispatch = useDispatch()
  const onErrorMutation = (err) => {
    console.error(err)
    showAlert(
      {
        text: err.toString(),
        status: 'error',
      },
      dispatch,
    )
  }
  const onSuccessMutation = (msg) => {
    showAlert(
      {
        text: msg.toString(),
        status: 'success',
      },
      dispatch,
    )
  }
  const [emitToAdmin] = useEmitInvokeLessonForAdmin((res) => {
    console.log('Sending emit to admin for new invoke lesson')
  }, console.error)
  const [invokeAdminMutation, {loading: loadInvoke}] = useInvokeAdmin((res) => {
    if (res?.invokeAdminToLesson) {
      // Actualiza chat
      onSuccessMutation(
        translate('virtualChat.invokeAdmin.success') || 'Success!',
      )
      refetch()
      // envia emit a admin para actualizar sus lecciones invocadas
      const adminId = res?.invokeAdminToLesson?.invokeAdminInfo?.invokeUserId
      // marca todos los mensajes del chat como leidos por el admin
      // (evita que le lleguen notificaciones por mensaje antiguos)
      markAsReader(eventID, adminId)
      emitToAdmin({variables: {userId: adminId}})
    }
  }, onErrorMutation)

  const [onlineStatus, setOnlineStatus] = useState(false)
  useEffect(() => {
    const userId = invokeAdminInfo?.id || invokeAdminInfo?.invokeUserId
    if (userId) {
      db.collection('usersStatus')
        .doc(eventID)
        .collection(userId)
        .doc('status')
        .onSnapshot((snapshot) => {
          const onlineStatus = snapshot.data()
          // guarda status del estudiante en el chat de la lección
          localStorage.setItem(
            `status-${eventID}-${userId}`,
            JSON.stringify(onlineStatus),
          )
          setOnlineStatus(onlineStatus)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invokeAdminInfo])

  return (
    <TabContent
      activeTab={
        adminInfo ? 'admin' : loadUser || loadInvoke ? 'loading' : 'show'
      }
    >
      <TabPane tabId='loading'>
        <IconLoad size={20} />
      </TabPane>
      <TabPane tabId='admin'>
        <UserBubble
          // imgContent={{img: avatar ? avatar : null, size: 16}}
          name={`${adminInfo?.firstName} ${adminInfo?.lastName}`}
          online={onlineStatus?.online}
          className='mr-2'
          toolTipProperties={{
            referenceID: `userBubbleAdmin${adminInfo?.id}`,
            toolTipText: `${adminInfo?.firstName} ${adminInfo?.lastName} (Admin)`,
          }}
        />
      </TabPane>
      <TabPane tabId='show'>
        <ToolTipInfo
          innerElement={
            <span tooltipId={`adminSupport-${eventID}`} className='text-white'>
              Contact to the admin
            </span>
          }
        >
          <span
            onClick={() => {
              invokeAdminMutation({
                variables: {lessonId: eventID},
              })
            }}
            className='btn-link h45'
          >
            S.O.S
          </span>
        </ToolTipInfo>
      </TabPane>
    </TabContent>
  )
}

export default InvokeAdmin
