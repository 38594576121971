import {gql, useMutation} from '@apollo/client'

const useInvokeAdmin = (onCompleted = () => {}, onError = () => {}) => {
  return useMutation(
    gql`
      mutation($lessonId: String!) {
        invokeAdminToLesson(lessonId: $lessonId) {
          invokeAdminInfo {
            invokeUserId
          }
        }
      }
    `,
    {onCompleted, onError},
  )
}

export default useInvokeAdmin
