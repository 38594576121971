import React, {useEffect, useState, useMemo} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import moment from 'moment-timezone'
import Skeleton from 'react-loading-skeleton'
import {
  ModalLayout,
  IconIllustratedCalendar,
  IconProgram,
  ListOption,
  StickyButton,
  IconArrowLeft,
  IconArrowRight,
} from '@Knowledge-OTP/znk-ui-components'
import PropTypes from 'prop-types'
import './index.css'
import {SET_DATA_MODAL_FORM_LIST} from '../../../../store/modal/const'
import {COUNSELING_ALL_TASKS} from '../../../../store/user/const'
import useShowScheduleMeeting from './useShowScheduledMeeting'
import {useGetOrientationHours} from '../../../Calendar/Lessons/Modal/ReschedulePendingLesson/Queries/useGetOrientationHours'
import useScheduleCounselorFromStudent from '../../../Task/Model/useScheduleCounselorFromStudent'
import {UseMyTeam} from '../../../../Model/Users/MyTeam/useMyTeam'
import {ParseFrames} from './utils'
import useAllUserTasks from '../../../Task/Model/useAllUserTasks'
import MessageModal from '../ViewTask/Components/Modals/messageModal'
import useTranslation from '../../../../i18n/useTranslation'
import useOperationTaskMutation from '../../../Task/Model/useOperationTask'
import {UseGetUserTask} from '../../../../Model/Users/Student/getUserTask/useGetUserTask'
import {TabContent, TabPane} from 'reactstrap'
import phaseNames from '../../Utils/phaseNames'
import ShowCounselorAssigned from './Components/ShowCounselorAssigned'
import {showAlert} from '../../../../common/Alert/util'
import {useQuery, gql} from '@apollo/client'
import useShowTask from '../ViewTask/useShowTask'
const ShowScheduledMeeting = () => {
  const {
    isOpen,
    toggle,
    task,
    close: closeScheduleMeeting,
    team,
  } = useShowScheduleMeeting()
  const {setTaskData} = useShowTask()
  const today = moment(new Date())

  // obtiene zona horaria del orientador
  const {data} = useQuery(
    gql`
      query($id: String!) {
        user(id: $id) {
          timezone
        }
      }
    `,
    {variables: {id: task?.userMeetingId}},
  )
  const orientatorTimezone = useMemo(() => {
    return data?.user?.timezone
  }, [data])

  const planId = useSelector(
    (state) => state.user?.info.studentInfo?.homeNotification?.councelingPlanId,
  )
  const [taskData, setTaskDataStatus] = useState(task)
  const [selectUser, setSelectUser] = useState('')
  const studentInfo = useSelector((state) => state.user.info)
  // eslint-disable-next-line
  const [getMyPlan, { data: dataTeam, loading: loadingTeam }] = UseMyTeam()
  const dispatch = useDispatch()
  const refetchUserData = useSelector((state) => state.user.refetch)
  const dataRedux = useSelector(
    (state) => state.modal.counseling.scheduleMeetingModal,
  )
  const {translate} = useTranslation()
  // const zone = moment.tz(studentInfo?.timezone).format('z')
  const zone = useSelector((state) => state.user.info.timezone)
  // eslint-disable-next-line
  const [selectedOption, setSelectedOption] = useState(null)
  const [selectedStartTime, setSelectedStartTime] = useState(null)
  const [selectedEndTime, setSelectedEndTime] = useState(null)
  const [selectedTime, setSelectedTime] = useState(null)
  const [message, setMessage] = useState('')
  const [messageModalOpen, setMessageModalOpen] = useState(false)
  const [allOptions, setAllOptions] = useState([])
  const [parsedArray, setParsedArray] = useState([])
  const [hideMoreOptions, setHideMoreOptions] = useState(false)
  const [
    getOrientationHours,
    {loading: loadingGetOrientationHours, data: dataGetOrientationHours},
  ] = useGetOrientationHours()

  const {refetch: refetchTasks} = useAllUserTasks({
    universityId: '',
    status: [],
    planId,
  })

  // eslint-disable-next-line
  const [getOneTask, { data: dataOneTask, loading: loadingOneTask, refetch: refetchOneTask }] = UseGetUserTask(
    (resp) => {
      const taskToSave = resp?.getUserTask
      dispatch({
        type: SET_DATA_MODAL_FORM_LIST,
        payload: 'counseling.scheduleMeetingModal',
        data: {
          ...dataRedux,
          task: taskToSave,
        },
      })
      setTaskData(taskToSave)
      refetchUserData()
      refetchTasks().then((resp) => {
        dispatch({
          type: COUNSELING_ALL_TASKS,
          payload: 'counseling.allTasks',
          data: resp?.data?.getUserTasks,
        })
      })
    },
    (err) => {
      console.error('Error on getting task : ', err)
    },
  )

  const [
    sendToReviewMutation,
    // eslint-disable-next-line
    { loading: loadSendToReviewMutation, data: dataSendToReviewMutation },
  ] = useOperationTaskMutation(
    () => {
      getOneTask({
        variables: {
          taskId: task?.id,
          stepId: task?.stepId,
          phase:
            task?.phase?.replace(/-([a-z])/g, function (g) {
              return g[1].toUpperCase()
            }) || task?.phaseName,
          planId,
        },
      })
      closeScheduleMeeting()
    },
    (err) => {
      console.error('Error on complete task : ', err)
    },
  )

  const sendToReviewFunction = () => {
    sendToReviewMutation({
      variables: {
        universities: taskData?.universities,
        operation: 'review',
        userId: taskData?.userId,
        taskId: taskData?.id,
        stepId: taskData?.stepId,
        phase: phaseNames[taskData?.phase] || phaseNames[taskData?.phaseName],
        planId: taskData?.planId || planId,
      },
    })
  }

  const {
    submit: scheduleMeetingnMutation,
    loading: loadScheduleMeetingMutation,
    // eslint-disable-next-line
    data: scheduleMeetingData,
  } = useScheduleCounselorFromStudent(
    () => {
      setSelectedTime(null)
      sendToReviewFunction()
    },
    (err) => {
      console.error('Error scheduling task : ', err)
      showAlert(
        {
          text: err.toString(),
          status: 'error',
        },
        dispatch,
      )
    },
  )
  const selectBlock = (timeMoment, selected) => {
    setSelectedTime(selected ? timeMoment : null)
    dispatch({
      type: SET_DATA_MODAL_FORM_LIST,
      payload: 'counseling.scheduleMeetingModal.data',
      data: {
        ...dataRedux,
        scheduled: selected ? moment(timeMoment) : null,
      },
    })
  }

  const camelToSnakeCase = (str) =>
    str.replace(/[A-Z]/g, (letter) => `-${letter.toLowerCase()}`)
  const scheduleMeetingFunction = () => {
    if (!selectUser) return
    try {
      scheduleMeetingnMutation({
        councelorInput: {
          startDate: selectedStartTime,
          endDate: selectedEndTime,
          councelorId: selectUser,
          phase: taskData?.phaseName
            ? camelToSnakeCase(taskData?.phaseName)
            : taskData?.phase,
          taskId: taskData?.id,
          stepId: taskData?.stepId,
          planId: taskData?.planId || planId,
        },
      })
    } catch (error) {
      setMessage(error.toString())
      setMessageModalOpen(true)
    }
  }

  // Function that shows more than the first 4 options to schedule
  const moreOptions = async () => {
    setHideMoreOptions(true)
    setParsedArray(
      ParseFrames(
        allOptions,
        moment.tz(zone).format('z'),
        moment.tz(orientatorTimezone).format('z'),
      ),
    )
  }
  // Function that add or subtract days to the current date for the mutation to get the disponibility
  // with the parameter "add" is for searching free hours in the future, and nothing in the past week.
  const nextWeekOptions = async (operation = '') => {
    operation === 'add' && setHideMoreOptions(false)
    getOrientationHours({
      variables: {
        educatorId: task?.userMeetingId ? task?.userMeetingId : selectUser,
        from:
          operation === 'add'
            ? moment(parsedArray[parsedArray.length - 1])
            : moment(parsedArray[0]).subtract(1, 'week'),
        to: moment(parsedArray[parsedArray.length - 1]).add(1, 'week'),
      },
    })
    setParsedArray(
      ParseFrames(
        allOptions,
        moment.tz(zone).format('z'),
        moment.tz(orientatorTimezone).format('z'),
      ),
    )
  }

  useEffect(() => {
    setHideMoreOptions(false)
  }, [])

  useEffect(() => {
    setTaskDataStatus(task)
  }, [task])

  useEffect(() => {
    if (planId && planId !== '') {
      getMyPlan({
        variables: {
          planId,
        },
      })
    }
    // eslint-disable-next-line
  }, [planId])

  useEffect(() => {
    if (dataRedux?.task?.userMeetingId)
      setSelectUser(dataRedux?.task?.userMeetingId)
    else if (team && !dataRedux?.task?.userMeetingId) setSelectUser(team[0]?.id)
  }, [team, dataRedux])

  useEffect(() => {
    if (selectUser && isOpen) {
      getOrientationHours({
        variables: {
          educatorId: task?.userMeetingId ? task?.userMeetingId : selectUser,
          from: moment(),
          to: taskData?.dueDate,
        },
      })
    }
    // eslint-disable-next-line
  }, [selectUser])

  useEffect(() => {
    if (dataGetOrientationHours) {
      setAllOptions(dataGetOrientationHours?.getOrientationHours)
      const responseArray = ParseFrames(
        dataGetOrientationHours?.getOrientationHours,
        moment.tz(zone).format('z'),
        moment.tz(orientatorTimezone).format('z'),
      )
      responseArray.length = 4
      setHideMoreOptions(false)
      setParsedArray(responseArray)
    }
    // eslint-disable-next-line
  }, [dataGetOrientationHours, orientatorTimezone])

  useEffect(() => {
    if (dataSendToReviewMutation) {
      refetchUserData()
      refetchTasks()
      getOneTask({
        variables: {
          taskId: task?.id,
          stepId: task?.stepId,
          phase:
            task?.phase?.replace(/-([a-z])/g, function (g) {
              return g[1].toUpperCase()
            }) || task?.phaseName,
          planId,
        },
      })
    }
    // eslint-disable-next-line
  }, [dataSendToReviewMutation])
  return (
    <>
      <ModalLayout
        isOpen={isOpen}
        toggle={toggle}
        className='modal-colored modal-sidebar modal-desktop-half white-modal'
        minHead={null}
        topHead={null}
        underHead={
          <div className='m-0 d-flex flex-column'>
            <IconIllustratedCalendar size={90} color='#000000' />
            <span className='mt-3 h2' style={{color: '#2B3C55'}}>
              {translate('counseling.selectDate')}
            </span>
            <span className='h4 mt-2' style={{color: '#2B3C55'}}>
              {`${taskData?.name} | ${studentInfo?.city}, GMT${moment
                .tz(zone)
                .format('z')}`}
            </span>
          </div>
        }
      >
        {/* Listado de horarios */}
        <TabContent activeTab={loadingGetOrientationHours ? 'loading' : 'show'}>
          <TabPane tabId='loading'>
            <Skeleton count={1} height={50} className='my-1' />
            <Skeleton count={4} height={40} className='my-1' />
            <Skeleton count={1} height={10} className='my-6 w-70' />
          </TabPane>
          <TabPane tabId='show'>
            <>
              <ShowCounselorAssigned
                team={team}
                userAssignedId={task?.userMeetingId}
              />
              {parsedArray &&
                parsedArray?.map((time, index) => {
                  const dateLabel = `${moment(time)
                    .clone()
                    .tz(zone)
                    .format('h:mma')} to ${moment(time)
                    .clone()
                    .tz(zone)
                    .add(30, 'minutes')
                    .format('h:mma')} | ${moment(time)
                    .clone()
                    .tz(zone)
                    .format('ll')}`
                  return (
                    <ListOption
                      key={index}
                      // classNameFilledIcon='znk-list-option-selected-inner-gold'
                      className={`text-title mb-2 ${
                        selectedTime === time ? 'selected-secondary' : ''
                      }`}
                      selectedCounseling={selectedTime === time}
                      classNameFilledIcon='text-counseling'
                      childClassName='d-flex flex-row'
                      onClick={() => {
                        setSelectedOption(time, index)
                        setSelectedStartTime(
                          moment(time).tz(zone).clone().format(),
                        )
                        setSelectedEndTime(
                          moment(time)
                            .tz(zone)
                            .clone()
                            .add(30, 'minutes')
                            .format(),
                        )
                        selectBlock(time, true)
                      }}
                      label={
                        <span
                          title={dateLabel}
                          className='d-flex flex-row align-items-center m-0 p-0 text-truncate'
                        >
                          <IconProgram size={18} className='mr-2' />
                          {dateLabel}
                        </span>
                      }
                    />
                  )
                })}
              {!hideMoreOptions ? (
                <div
                  className='h45 text-gray my-6'
                  hidden={hideMoreOptions}
                  onClick={() => moreOptions()}
                >
                  {translate('counseling.needMoreOptions')}
                </div>
              ) : (
                <div
                  className='h45 text-gray my-6 d-flex justify-content-between'
                  hidden={!hideMoreOptions}
                >
                  {Math.abs(
                    moment.duration(today.diff(parsedArray[0])).asDays(),
                  ) < 5 ? (
                    <IconArrowLeft onClick={() => {}} />
                  ) : (
                    <IconArrowLeft onClick={() => nextWeekOptions()} />
                  )}
                  <IconArrowRight
                    onClick={() => nextWeekOptions('add')}
                    className='d-flex justify-content-end'
                  />
                </div>
              )}
            </>
          </TabPane>
        </TabContent>
        <StickyButton
          label='Continue'
          check
          disabled={!selectedTime}
          btnClassName='mb-2'
          className='mb-2 w-100'
          size='large'
          activity={
            loadScheduleMeetingMutation ||
            loadSendToReviewMutation ||
            loadingOneTask
          }
          onClickButtonFunction={() => {
            scheduleMeetingFunction()
          }}
          color='counseling'
        />
      </ModalLayout>
      <MessageModal
        isOpen={messageModalOpen}
        toggle={() => setMessageModalOpen(!messageModalOpen)}
        messageBody={message}
      />
    </>
  )
}

ShowScheduledMeeting.defaultProps = {
  isOpen: false,
  toggle: () => {},
}

ShowScheduledMeeting.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
}

export default ShowScheduledMeeting
