import validate from 'validate.js'

const constraints = {
  first: {
    presence: true,
    length: {
      minimum: 1,
      // maximum: 20,
    },
  },
  last: {
    presence: true,
    length: {
      minimum: 1,
      // maximum: 20,
    },
  },
  email: {
    presence: true,
    email: true,
  },
  prefijo: {
    presence: true,
    format: /\+[0-9| ]+/,
  },
  prefijoParent: {
    presence: {allowEmpty: true},
    format: /\+[0-9| ]+/,
  },
  tel: {
    presence: true,
    format: /^[0-9]+/,
  },
  dia: {
    presence: true,
  },
  mes: {
    presence: true,
  },
  year: {
    presence: true,
  },
  address: {
    presence: true,
    length: {
      minimum: 1,
    },
  },
  city: {
    presence: true,
    length: {
      minimum: 1,
    },
  },
  state: {
    presence: true,
    length: {
      minimum: 1,
    },
  },
  zip: {
    presence: true,
    length: {
      minimum: 1,
    },
  },
  country: {presence: true},
  spoken: {
    presence: true,
    length: {
      minimum: 1,
    },
  },
  gender: {presence: true},
  studentType: {presence: true},
  IdParent: {presence: true},
  emailParent: {
    presence: true,
    length: {
      minimum: 1,
    },
  },
  firstNameParent: {
    presence: true,
    length: {
      minimum: 1,
    },
  },
  lastNameParent: {
    presence: true,
    length: {
      minimum: 1,
    },
  },
  telParent: {
    presence: true,
    format: /^[0-9]+/,
  },
  aditionalInfo: {
    presence: false,
  },

  anoGraduacion: {
    presence: true,
  },
  mesGraduacion: {
    presence: true,
  },
  institution: {
    presence: true,
    length: {
      minimum: 1,
    },
  },
}
export default (data, family = true) => {
  return validate(
    data,
    family
      ? constraints
      : {
          ...constraints,
          IdParent: {
            presence: false,
          },
          emailParent: {
            presence: false,
          },
          firstNameParent: {
            presence: false,
          },
          lastNameParent: {
            presence: false,
          },
          // telParent: {
          //   presence: false,
          //   format: /^[0-9]+/,
          // },
        },
  )
}
