import React, {useMemo} from 'react'
import {
  Button,
  IconBrand,
  ListItem,
  useWindowDimensions,
} from '@Knowledge-OTP/znk-ui-components'
import BreakDown from '../../components/BreakDown'
import WeeklySchedule from '../../components/WeeklySchedule'
import PaymentSchedule from '../../components/PaymentSchedule'
import CheckInsPlan from '../../components/CheckInsPlan'
import useTranslation from '../../../../i18n/useTranslation'
import {useDispatch, useSelector} from 'react-redux'
import {SET_DATA_MODAL_FORM_LIST} from '../../../../store/modal/const'
import moment from 'moment'
import formatNumber from '../../../../helpers/formatNumber'

export const InformationHeader = (props) => {
  const {translate} = useTranslation()
  const {dataPlan} = useSelector((state) => state.modal.onboarding.data)
  const now = useMemo(() => {
    return moment()
  }, [])

  const timeLabel = dataPlan?.isTotalLesson
    ? moment(dataPlan?.startDate) <= now
      ? translate('home.onboard.approve.before.startwhen')
      : `${translate('home.onboard.approve.before.begin')} ${moment(
          dataPlan?.startDate?.split('T')[0],
        )
          .utc()
          .format('MMMM Do')}`
    : `${translate('misc.rangeDate', {
        from: dataPlan?.adjustedConfig?.adjustedParts
          ?.map((part) => {
            if (moment(part.from).isBefore(moment())) {
              return moment().utc().format('MMM Do, YYYY')
            } else {
              return moment(dataPlan?.startDate.split('T')[0])
                .utc()
                .format('MMM Do, YYYY')
            }
          })
          .join(','),
        to: moment(dataPlan?.endDate.split('T')[0])
          .utc()
          .format('MMM Do, YYYY'),
      })}`

  const titlePlan = dataPlan?.isTestDate
    ? `${dataPlan?.serviceName} - ${moment(dataPlan?.testDate)
        .utc()
        .format('MMM Do, YYYY')}`
    : `${dataPlan?.serviceName} - ${moment(dataPlan?.startDate)
        .utc()
        .format('MMM Do, YYYY')}`

  return (
    <div className='m-0 p-0'>
      <IconBrand size={80} color='white' />
      <div>
        <h1 className='mb-4'>
          <b>{translate('home.onboard.approve.beginwork')}</b>
        </h1>
        <div className='my-2 d-flex flex-column'>
          <h3 className='my-2'>
            <b>{titlePlan}</b>
          </h3>
          <span className='my-2'>{timeLabel}</span>
        </div>
      </div>
    </div>
  )
}

export const InformationBody = (props) => {
  const {translate} = useTranslation()
  const {breakWidth} = useWindowDimensions()

  const dispatch = useDispatch()
  const reduxSmall = useSelector((state) => state.modal.onboarding.smallModal)
  const {dataPlan, scheduledTimes} = useSelector(
    (state) => state.modal.onboarding.data,
  )

  const services = useSelector((state) => state.services.services)
  const groupLessons =
    dataPlan?.groupInfo?.PartsSchedule?.reduce(
      (arrayTimes, {lessonsPerWeekGroup}, indexPart) => {
        arrayTimes.push(
          Object.values(
            lessonsPerWeekGroup.reduce((obj, {topicId, day, hour}) => {
              if (obj[topicId]) {
                obj[topicId].lessons += 1
                obj[topicId].days.push({day, hour})
              } else {
                obj = {
                  ...obj,
                  [topicId]: {
                    lessons: 1,
                    days: [
                      {
                        day,
                        hour,
                      },
                    ],
                    name: services[dataPlan?.serviceId].topics[topicId].name,
                  },
                }
              }
              return obj
            }, {}),
          ),
        )
        return arrayTimes
      },
      [],
    ) || []

  return (
    <div
      className={`m-0 p-0 ${
        breakWidth === 'SM' ? 'px-3' : breakWidth === 'MD' ? 'px-5' : 'px-10'
      }`}
    >
      <BreakDown
        data={dataPlan?.paymentItemsConfig}
        isPerMonth={dataPlan?.paymentPerMonth}
        totalLessons={dataPlan?.totalLessons}
        hasGroupPricing={dataPlan?.isGroup}
        individualPricing={dataPlan?.lessonRate}
        groupPricing={{
          groupPricingRate: dataPlan?.groupLessonRate || 0,
          totalGroupLessons: (
            dataPlan?.adjustedConfig?.adjustedParts ??
            dataPlan?.groupInfo?.PartsSchedule ??
            []
          ).reduce((total, part) => {
            return (
              total +
              part?.lessonsPerWeekGroup?.reduce((totalPart, {totalLessons}) => {
                return totalPart + totalLessons
              }, 0)
            )
          }, 0),
        }}
      />
      <div className='borderTop' />
      {dataPlan.ranges.map((__, indexPart) => (
        <WeeklySchedule
          key={indexPart}
          indexPart={indexPart}
          data={Object.entries(scheduledTimes)
            .filter(([key]) => parseInt(key.split('-')[1]) === indexPart)
            .map(([__, value]) => value)}
          className={`${indexPart > 0 ? 'mt-0 mb-5' : 'mt-5 mb-0'}`}
          dataPlan={dataPlan}
          showTotalLessons={dataPlan?.isTotalLesson}
          useTotalLessonText={dataPlan?.isTotalLesson}
          hideTitle={indexPart > 0}
          hideSubTitle={false}
          hasGroupLesson={dataPlan?.isGroup}
          groupPartData={groupLessons[indexPart]}
          showCollapse
        />
      ))}
      <div className='borderTop' />
      <CheckInsPlan data={dataPlan} />
      <div className='borderTop' />
      <PaymentSchedule
        post={false}
        data={dataPlan.paymentItemsConfig}
        selectedPayments={dataPlan.paymentItemsConfig.filter(
          (p) => p.type !== 'others-fixed',
        )}
        dataPlan={dataPlan}
        periodicity={{value: 'recomended'}}
      />
      <div className='borderTop' />
      <ListItem
        showTopLine={false}
        showBottomLine={false}
        className='pl-0 my-2'
        Left={
          <div className='d-flex align-items-center text-muted'>
            <span>
              <b>{translate('home.onboard.approve.paymentTotal')}</b>
            </span>
          </div>
        }
        Right={
          <span>
            <b>
              $
              {dataPlan.paymentItemsConfig
                ? formatNumber(
                    dataPlan.paymentItemsConfig
                      .map((pay) => {
                        return pay.amount
                      })
                      .reduce((count, number) => {
                        return count + number
                      }, 0),
                  )
                : parseInt(0).toFixed(2)}
            </b>
          </span>
        }
      />
      <div className='borderTop' />
      <Button
        className='w-100 m-0 my-2 p-2'
        color='primary'
        label={translate('home.onboard.approve.approvePlan')}
        check
        onClick={() => {
          dispatch({
            type: SET_DATA_MODAL_FORM_LIST,
            payload: 'onboarding.smallModal',
            data: {...reduxSmall, isOpen: true},
          })
        }}
      />
    </div>
  )
}
