import React, {useEffect, useState} from 'react'
import {
  Field,
  Form,
  LabeledField,
  PlainTextInput,
  Select,
} from '@Knowledge-OTP/znk-ui-components'
import {FormGroup, Label} from 'reactstrap'
import moment from 'moment'
// import {useSelector} from 'react-redux'
import useTranslation from '../../../i18n/useTranslation'
import {gql, useMutation} from '@apollo/client'
import validateForm from './validation'
import {isEmpty, pick} from 'lodash'

const StudentInfo = (props) => {
  const {
    studentInfo,
    setStudentInfoCallBack,
    setErrorValues,
    refetch: refetchUser,
    setLoading = () => {},
  } = props
  // const {refetch: refetchUser} = useSelector((state) => state.user)
  const {translate} = useTranslation()
  const [validationErrors, setValidationErrors] = useState({})
  const [form, setForm] = useState({
    month: studentInfo.graduationDate
      ? moment(studentInfo.graduationDate).month()
      : null,
    year: studentInfo.graduationDate
      ? moment(studentInfo.graduationDate).year()
      : null,
    ...studentInfo,
  })
  const [updInfo, {loading}] = useMutation(
    gql`
      mutation($studentInfo: StudentInput!) {
        setMyStudentInfo(studentInfo: $studentInfo) {
          id
          paymentMethod {
            ... on CardPaymentMethod {
              last4
              brand
              expYear
              expMonth
            }
          }
          emails {
            address
            verified
          }
          countryId
          gender
          spokenLanguages
          address
          city
          state
          zipCode
          firstName
          lastName
          phone
          timezone
          birthdate
          studentInfo {
            institution
            graduationDate
            gpa
            aditionalInfo
            parents {
              firstName
              lastName
              managePayments
              manageApprovals
              email
              parentUserId
              phone
            }
          }
          roles
        }
      }
    `,
    {
      update: (cache, {data}) => {
        refetchUser()
      },
    },
  )

  useEffect(() => {
    if (loading) {
      setLoading(true)
    }
    // eslint-disable-next-line
  }, [loading])

  const submit = () => {
    const updData = pick(form, ['institution', 'aditionalInfo'])
    updData.gpa = parseFloat(form.gpa)
    updData.graduationDate = moment()
      .year(form.year)
      .month(form.month)
      .date('01')
      .toISOString()
    updInfo({variables: {studentInfo: updData}})
      .then((data) => {
        console.log(data)
      })
      .catch(() => {
        console.error('Error to update Student Info.')
      })
  }
  const checkErrors = () => {
    const errors = validateForm(form)
    if (!isEmpty(errors)) {
      setValidationErrors(errors)
      return
    }
    setValidationErrors({})
  }
  useEffect(() => {
    setErrorValues((v) => {
      return {
        ...v,
        studentInfoErrors: validateForm(form),
      }
    })
    setStudentInfoCallBack(() => submit)
    checkErrors()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form])
  return (
    <Form value={form} onChange={setForm} validationErrors={validationErrors}>
      <FormGroup>
        <Label>
          {translate('profile.actions.editStudentInfo.institution')}
        </Label>
        <Field
          type={LabeledField}
          component={PlainTextInput}
          fieldName='institution'
          placeholder='Institution'
        />
      </FormGroup>
      {/* <FormGroup>
        <Label>{translate('profile.actions.editStudentInfo.gpa')}</Label>
        <Field
          type={LabeledField}
          component={PlainTextInput}
          fieldName='gpa'
          placeholder='0'
        />
      </FormGroup> */}
      <FormGroup>
        <Label>{translate('profile.actions.editStudentInfo.gradDate')}</Label>
        <div className='row'>
          <div className='col-7 pr-0'>
            <Field
              type={LabeledField}
              component={Select}
              fieldName='month'
              className='h-100'
              items={new Array(12).fill(0).map((_, idx) => ({
                label: moment().month(idx).format('MMMM'),
                id: idx,
              }))}
              listItem='small'
              itemDivider='middle'
              multiple={false}
              border='noRight'
            />
          </div>
          <div className='col-5 pl-1'>
            <Field
              type={LabeledField}
              component={Select}
              fieldName='year'
              className='h-100'
              items={new Array(25)
                .fill(0)
                .map((_, idx) => ({label: 2015 + idx, id: 2015 + idx}))}
              listItem='small'
              itemDivider='middle'
              multiple={false}
              border='noLeft'
            />
          </div>
        </div>
      </FormGroup>
      <FormGroup>
        <Label>
          {translate('profile.actions.editStudentInfo.additionalInfo')}
        </Label>
        <Field
          type={LabeledField}
          component={PlainTextInput}
          fieldName='aditionalInfo'
          fieldType='textarea'
          placeholder={translate(
            'profile.actions.editStudentInfo.additionalInfoPlaceholder',
          )}
        />
      </FormGroup>
    </Form>
  )
}

export default StudentInfo
