import React, {useEffect, useState, useMemo} from 'react'
import {
  ModalScroll,
  useWindowDimensions,
  Avatar,
  ListAvatars,
  IconClose,
  IconLink,
  IconWspAlert,
  IconLoad,
} from '@Knowledge-OTP/znk-ui-components'
import {useDispatch, useSelector} from 'react-redux'
import useTranslation from '../../../../i18n/useTranslation'
import {PendingHeaderModal, PendingBodyModal} from './pending/index.js'
import {AttendedHeaderModal, AttendedBodyModal} from './attended/index.js'
import {IncompleteHeaderModal, IncompleteBodyModal} from './incomplete/index.js'
import {
  RescheduledHeaderModal,
  RescheduledBodyModal,
} from './rescheduled/index.js'
import {InProgressBodyModal} from './inprogress/index.js'
import moment from 'moment'
import AddSubstitute from '../../../../Model/Lessons/AddSubstitute'
import {hasHttp} from '../utils'
import useLinkLesson from './useLinkLesson'
import {showAlert} from '../../../../common/Alert/util'
import VirtualChat from '../../../VirtualChat'
import {TabContent, TabPane} from 'reactstrap'
import UsersListDataLesson from '../../../VirtualChat/utils/usersListDataLesson'
import collectionType from '../../../VirtualChat/utils/collectionType'
import GetCourseMaterials from './Components/GetCourseMaterials'

const InfoLesson = (props) => {
  const {refetchCalendarQuery} = props
  const dispatch = useDispatch()
  const reduxData = useSelector((state) => state.modal.calendar.infoLessonModal)
  const userInfo = useSelector((state) => state.user.info)
  const {isOpen, dataLesson} = reduxData
  const wasSubstituted = !!(
    dataLesson?.substitute &&
    dataLesson?.substitute?.educatorUserId !== userInfo?.id
  )
  const {breakWidth} = useWindowDimensions()
  const {translate} = useTranslation()
  const [tabValue, setTabValue] = useState(reduxData?.modalType || 'modals')
  // useEffect(() => {
  //   setTabValue(reduxData?.modalType || 'modals')
  // }, [reduxData])
  const toggle = () => {
    // if (tabValue === 'chat') {
    //   setTabValue('modals')
    // } else {
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'calendar.infoLessonModal',
      data: {
        isOpen: !isOpen,
        dataLesson,
      },
    })
    // }
  }

  const [
    student,
    educator,
    createdDate,
    updateDate,
    statusOfInfoLesson,
    dataLessonProp,
  ] = useMemo(() => {
    /**
      *->Incomplete (SCHEDULED y posterior al enddate): solo cuando la lección ya terminó => incomplete
      ->Current/inprogress (SCHEDULED y entre startdate y enddate): => inprogress
      ---> para el estudiante es la siguiente
      ---> Cuando quedan menos de 10 minuts para el start time
      ---> Está en progreso
      ->scheduled (SCHEDULED)(azul oscuro o gris):  Aun no comienza / es futura => sheduled
      ->Attended/missed (FINISHED): Cuando status es igual a finished (para que pase a finished tiene que haber completado la asistencia) => finished
      * **/
    const studentAux = dataLesson
      ? dataLesson.student || dataLesson.studentPlan || dataLesson.studentGroup
      : null
    let statusOfInfoLesson = 'scheduled'

    if (dataLesson?.status === 'finished') {
      if (Array.isArray(studentAux)) {
        statusOfInfoLesson = 'finished-group'
      } else {
        statusOfInfoLesson = `finished-${
          studentAux?.attendanceStatus || 'missed'
        }`
      }
    } else if (dataLesson?.status === 'pending') {
      statusOfInfoLesson = 'pending'
    } else {
      if (
        moment(dataLesson?.startDate) < moment() &&
        moment() < moment(dataLesson?.endDate)
      ) {
        // lección en curso
        statusOfInfoLesson = 'inprogress'
      } else if (moment() > moment(dataLesson?.endDate)) {
        // lección ya pasó y no está finished
        statusOfInfoLesson = 'incomplete'
      } else if (moment() < moment(dataLesson?.startDate)) {
        if (
          ['checkIn', 'attended'].includes(dataLesson?.educator?.attendanceStatus) ||
          ['checkIn', 'attended'].includes(dataLesson?.substitute?.attendanceStatus)
        ) {
          // lección con checkIn
          statusOfInfoLesson = 'inprogress'
        } else {
          // lección aún no sucede
          statusOfInfoLesson = 'scheduled'
        }
      }
    }
    return [
      studentAux,
      dataLesson
        ? dataLesson?.substitute
          ? dataLesson?.substitute
          : dataLesson.educator
        : null,
      dataLesson &&
        (dataLesson.IndividualCreated ||
          dataLesson.PlanCreated ||
          dataLesson.GroupCreated ||
          dataLesson.CounselorCreated),
      dataLesson &&
        (dataLesson.IndividualUpdate ||
          dataLesson.PlanUpdate ||
          dataLesson.GroupUpdate ||
          dataLesson.CounselorUpdate),
      statusOfInfoLesson,
      dataLesson,
    ]
  }, [dataLesson])

  const [statusOfInfoLessonState, setStatusOfInfoLessonState] = useState(
    statusOfInfoLesson,
  )

  const usersListData = UsersListDataLesson(dataLessonProp)

  useEffect(() => {
    setStatusOfInfoLessonState(statusOfInfoLesson)
  }, [statusOfInfoLesson])

  const RenderStudents = ({student}) => {
    return (
      <div className='d-flex flex-row'>
        {Array.isArray(student) ? (
          <>
            <ListAvatars
              direction='right'
              avatars={student.map((studentUser) => ({
                name: studentUser
                  ? `${studentUser.firstName} ${studentUser.lastName}`
                  : 'Not Found',
              }))}
            />
            <span className='align-self-center ml-2 text-title'>
              {student.length} {translate('modal.infolesson.students')}{' '}
            </span>
          </>
        ) : (
          <>
            <Avatar
              name={student && `${student.firstName} ${student.lastName}`}
              named={false}
              uri={null}
              size='avatar-medium mr-1'
            />
            <span className='align-self-center'>
              {student && `${student.firstName} ${student.lastName} `}
              <small className='text-muted'>{`(${translate(
                'modal.infolesson.student',
              )})`}</small>
            </span>
          </>
        )}
      </div>
    )
  }

  // marcar attendance
  const [educatorAttendance, setEducatorAttendance] = useState(
    educator?.attendanceStatus,
  )
  const [studentAttendance, setStudentAttendace] = useState(null)
  useEffect(() => {
    if (student && dataLesson) {
      setStudentAttendace(
        Array.isArray(student)
          ? student.reduce((objStu, stu) => {
              return {...objStu, [stu.studentUserId]: stu.attendanceStatus}
            }, {})
          : {[student.studentUserId]: student.attendanceStatus},
      )
      // setEducatorAttendance(
      //  dataLesson?.substitute?.attendanceStatus || educator.attendanceStatus,
      // )
    }
  }, [student, dataLesson])

  // verifica si cambio el attendaceStatus original de la lección
  const checkAttendanceChanges = useMemo(() => {
    let val = false // ¿hay cambios?
    // val =
    //  educatorAttendance !==
    //  (dataLesson?.substitute?.attendanceStatus || educator?.attendanceStatus)
    if (!val) {
      val =
        studentAttendance &&
        Object.entries(studentAttendance).reduce(
          (validationAccu, [idStudent, attendance]) => {
            const stu = Array.isArray(student)
              ? student.filter((stu) => stu.studentUserId === idStudent)[0]
              : student
            return validationAccu || stu?.attendanceStatus !== attendance
          },
          false,
        )
    }
    return val
  }, [studentAttendance, student])
  const resetAttendance = () => {
    setStudentAttendace(
      Array.isArray(student)
        ? student.reduce((objStu, stu) => {
            return {...objStu, [stu.studentUserId]: stu.attendanceStatus}
          }, {})
        : {[student.studentUserId]: student.attendanceStatus},
    )
    // setEducatorAttendance(educator.attendanceStatus)
  }

  const setDataLessonToRedux = (newDataLesson) => {
    // setea lección modificada en redux, se usa luego de ejecutar una mutación
    dispatch({
      type: 'SET_DATA_MODAL_FORM_LIST',
      payload: 'calendar.infoLessonModal',
      data: {
        isOpen: true,
        dataLesson: newDataLesson,
      },
    })
  }

  const {
    submit: addSubstituteMutation,
    load: loadAddSubstituteMutation,
  } = AddSubstitute(
    (res) => {
      dispatch({
        type: 'SET_DATA_MODAL_FORM_LIST',
        payload: 'plan.addSustituteEducator',
        data: {
          isOpen: false,
          idTopic: '',
          setEducator: () => {},
          topicName: '',
          educator: {},
          idService: '',
        },
      })
      dispatch({
        type: 'SET_DATA_MODAL_FORM_LIST',
        payload: 'plan.infoLessonModal',
        data: {
          isOpen: true,
          dataLesson: {
            ...dataLesson,
            substitute: res?.data?.addSubstitute?.substitute || null,
          },
        },
      })
      // setSubstituteEducator(res?.data?.addSubstitute?.substitute || null)
    },
    (error) => {
      console.error(error)
      alert(error)
    },
  )

  const getvclink = useLinkLesson()
  const [loadCopy, setLoadCopy] = useState(false)
  const studentOptions = (status) => (student) => {
    // statusOfInfoLessonState
    const [copySuccess, copyError] = [
      () => {
        // success
        showAlert(
          {text: translate('modal.infolesson.copy'), status: 'success'},
          dispatch,
        )
        setLoadCopy(false)
      },
      () => {
        // error
        showAlert(
          {text: translate('modal.infolesson.copyError'), status: 'error'},
          dispatch,
        )
        setLoadCopy(false)
      },
    ]
    const options = [
      {
        component: (
          <span
            onClick={() => {
              if (!loadCopy) {
                // si location es VCR es launchURL, sino es location.linkStudent!
                setLoadCopy(true)
                getvclink(dataLesson?.id, student?.studentUserId || student?.id)
                  .then(
                    ({launchUrl, location}) => {
                      if (
                        location.type === 'virtualClassroom' ||
                        location.type === 'zinkerzPlatform'
                      )
                        navigator.clipboard
                          .writeText(hasHttp(launchUrl))
                          .then(copySuccess)
                          .catch(copyError)
                      else
                        navigator.clipboard
                          .writeText(hasHttp(location.linkStudent))
                          .then(copySuccess)
                          .catch(copyError)
                    },
                    (error) => {
                      console.error(error)
                      navigator.clipboard
                        .writeText(hasHttp(dataLesson.location.linkStudent))
                        .then(copySuccess)
                        .catch(copyError)
                    },
                  )
                  .catch((error) => {
                    console.error(error)
                    navigator.clipboard
                      .writeText(hasHttp(dataLesson.location.linkStudent))
                      .then(copySuccess)
                      .catch(copyError)
                  })
              }
            }}
          >
            {loadCopy ? (
              <IconLoad className='mr-2' />
            ) : (
              <IconLink className='mr-2' />
            )}{' '}
            {translate('modal.infolesson.copylocationlink')}
          </span>
        ),
      },
      {
        component: (
          <GetCourseMaterials
            serviceId={dataLesson?.service?.id}
            topicId={dataLesson?.topic?.id}
            studentId={student?.studentUserId}
          />
        ),
      },
    ]

    if (status === 'inprogress') {
      options.push({
        component: (
          <span
            onClick={() => {
              dispatch({
                type: 'SET_DATA_MODAL_FORM_LIST',
                payload: 'calendar.wspAlert',
                data: {
                  isOpen: true,
                  dataLesson: dataLesson,
                  studentsId:
                    student?.studentUserId || student?.id
                      ? [student?.studentUserId || student?.id]
                      : [],
                  educatorId: null,
                },
              })
            }}
          >
            <IconWspAlert className='mr-2' />{' '}
            {translate('modal.infolesson.sendwspalert')}
          </span>
        ),
      })
    }
    return options
  }

  return (
    <ModalScroll
      buttonLabel={null}
      modalScrollClassName='h-100'
      className={`modal-colored modal-${
        breakWidth === 'SM'
          ? 'botbar'
          : statusOfInfoLessonState === 'pending' || dataLesson?.showInCenter
          ? 'sidebar modal-centered'
          : 'sidebar modal-desktop-half'
      }  fixed-head-modal ${
        statusOfInfoLessonState === 'scheduled'
          ? 'scheduled'
          : [
              'finished-attended',
              'finished-missed',
              'finished-group',
              'incomplete',
            ].includes(statusOfInfoLessonState)
          ? 'white'
          : ['pending'].includes(statusOfInfoLessonState)
          ? 'whiteblue'
          : 'upcoming'
      }-modal`}
      isOpen={isOpen}
      toggle={toggle}
      MinHead={
        <div className='m-0 p-0 d-flex flex-row w-100 h-100 justify-content-between p-5'>
          <div className='d-flex flex-row align-items-center'>
            {dataLessonProp && <span>{`${dataLessonProp?.title}`}</span>}
          </div>
          <div className='d-flex flex-row align-items-center'>
            <IconClose className='text-cancel' onClick={toggle} />
          </div>
        </div>
      }
      Head={
        <div
          className={`modal-header px-6 px-sm-7 d-flex flex-column ${
            statusOfInfoLessonState === 'pending' ? 'pb-0 pt-5' : ''
          }`}
          style={
            statusOfInfoLessonState === 'pending'
              ? {zIndex: 10, minHeight: 'unset'}
              : {zIndex: 10}
          }
        >
          {['scheduled', 'inprogress'].includes(statusOfInfoLessonState) ? (
            <PendingHeaderModal dataLesson={dataLessonProp} toggle={toggle} />
          ) : statusOfInfoLessonState === 'incomplete' ? (
            <IncompleteHeaderModal
              dataLesson={dataLessonProp}
              toggle={toggle}
            />
          ) : statusOfInfoLessonState === 'pending' ? (
            <RescheduledHeaderModal
              dataLesson={dataLessonProp}
              toggle={toggle}
            />
          ) : (
            <AttendedHeaderModal
              lessonStatus={statusOfInfoLessonState}
              dataLesson={dataLessonProp}
              toggle={toggle}
            />
          )}
          {/* <span>{`id: ${dataLesson?.id}`}</span> */}
        </div>
      }
    >
      <div className='modal-body d-flex flex-column py-2 px-7 m-0 h-100'>
        <TabContent activeTab={tabValue} className='h-100'>
          <TabPane tabId='chat' className='h-100'>
            <VirtualChat
              refetchLessons={() => {
                // actualiza calendario de lecciones
                refetchCalendarQuery()
              }}
              active={tabValue === 'chat'}
              collectionType={collectionType[dataLessonProp?.__typename]}
              usersList={usersListData}
              collection={dataLessonProp}
              {...dataLessonProp}
              toggle={toggle}
              backArrow={() => {
                setTabValue('modals')
              }}
              student={student}
              educator={educator}
              createdDate={createdDate}
              updateDate={updateDate}
              RenderStudents={RenderStudents}
              setDataLessonToRedux={setDataLessonToRedux}
              educatorAttendanceState={[
                educatorAttendance,
                setEducatorAttendance,
              ]}
              studentAttendanceState={[studentAttendance, setStudentAttendace]}
              checkAttendanceChanges={checkAttendanceChanges}
              resetAttendance={resetAttendance}
              addSubstituteMutation={addSubstituteMutation}
              loadAddSubstituteMutation={loadAddSubstituteMutation}
              studentOptions={studentOptions(statusOfInfoLessonState)}
            />
            {/* {tabValue === 'chat' && (
              )} */}
          </TabPane>
          <TabPane tabId='modals'>
            {statusOfInfoLessonState === 'scheduled' ? (
              <PendingBodyModal
                dataLesson={dataLessonProp}
                toggle={toggle}
                student={student}
                educator={educator}
                wasSubstituted={wasSubstituted}
                createdDate={createdDate}
                updateDate={updateDate}
                RenderStudents={RenderStudents}
                setDataLessonToRedux={setDataLessonToRedux}
                educatorAttendanceState={[
                  educatorAttendance,
                  setEducatorAttendance,
                ]}
                studentAttendanceState={[
                  studentAttendance,
                  setStudentAttendace,
                ]}
                checkAttendanceChanges={checkAttendanceChanges}
                resetAttendance={resetAttendance}
                addSubstituteMutation={addSubstituteMutation}
                loadAddSubstituteMutation={loadAddSubstituteMutation}
                studentOptions={studentOptions(statusOfInfoLessonState)}
              />
            ) : statusOfInfoLessonState === 'inprogress' ? (
              <InProgressBodyModal
                dataLesson={dataLessonProp}
                toggle={toggle}
                student={student}
                educator={educator}
                wasSubstituted={wasSubstituted}
                createdDate={createdDate}
                updateDate={updateDate}
                RenderStudents={RenderStudents}
                setDataLessonToRedux={setDataLessonToRedux}
                educatorAttendanceState={[
                  educatorAttendance,
                  setEducatorAttendance,
                ]}
                studentAttendanceState={[
                  studentAttendance,
                  setStudentAttendace,
                ]}
                checkAttendanceChanges={checkAttendanceChanges}
                resetAttendance={resetAttendance}
                addSubstituteMutation={addSubstituteMutation}
                loadAddSubstituteMutation={loadAddSubstituteMutation}
                setStatusOfInfoLessonState={setStatusOfInfoLessonState}
                refetchCalendarQuery={refetchCalendarQuery}
                studentOptions={studentOptions(statusOfInfoLessonState)}
                setTabValue={setTabValue}
              />
            ) : statusOfInfoLessonState === 'incomplete' ? (
              <IncompleteBodyModal
                dataLesson={dataLessonProp}
                toggle={toggle}
                student={student}
                educator={educator}
                wasSubstituted={wasSubstituted}
                createdDate={createdDate}
                updateDate={updateDate}
                RenderStudents={RenderStudents}
                setStatusOfInfoLessonState={setStatusOfInfoLessonState}
                refetchCalendarQuery={refetchCalendarQuery}
                setDataLessonToRedux={setDataLessonToRedux}
                educatorAttendanceState={[
                  educatorAttendance,
                  setEducatorAttendance,
                ]}
                studentAttendanceState={[
                  studentAttendance,
                  setStudentAttendace,
                ]}
                checkAttendanceChanges={checkAttendanceChanges}
                resetAttendance={resetAttendance}
                addSubstituteMutation={addSubstituteMutation}
                loadAddSubstituteMutation={loadAddSubstituteMutation}
                studentOptions={studentOptions(statusOfInfoLessonState)}
              />
            ) : statusOfInfoLessonState === 'pending' ? (
              <RescheduledBodyModal
                dataLesson={dataLessonProp}
                toggle={toggle}
                student={student}
                educator={educator}
                wasSubstituted={wasSubstituted}
                createdDate={createdDate}
                updateDate={updateDate}
                RenderStudents={RenderStudents}
                lessonStatus={statusOfInfoLessonState}
                setDataLessonToRedux={setDataLessonToRedux}
                addSubstituteMutation={addSubstituteMutation}
                loadAddSubstituteMutation={loadAddSubstituteMutation}
                studentOptions={studentOptions(statusOfInfoLessonState)}
              />
            ) : (
              <AttendedBodyModal
                dataLesson={dataLessonProp}
                toggle={toggle}
                student={student}
                educator={educator}
                wasSubstituted={wasSubstituted}
                createdDate={createdDate}
                updateDate={updateDate}
                RenderStudents={RenderStudents}
                lessonStatus={statusOfInfoLessonState}
                setDataLessonToRedux={setDataLessonToRedux}
                addSubstituteMutation={addSubstituteMutation}
                loadAddSubstituteMutation={loadAddSubstituteMutation}
                studentOptions={studentOptions(statusOfInfoLessonState)}
              />
            )}
          </TabPane>
        </TabContent>
      </div>
    </ModalScroll>
  )
}

export default InfoLesson
